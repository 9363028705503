import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LocalhostController"] */ "/vercel/path0/node_modules/@vercel/toolbar/dist/next/localhost-controller.js");
;
import(/* webpackMode: "eager", webpackExports: ["UnmountController"] */ "/vercel/path0/node_modules/@vercel/toolbar/dist/next/unmount.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Inter-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Inter-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/Inter-SemiBold.woff\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/Inter-Bold.woff\",\"weight\":\"700\"}],\"variable\":\"--font-inter\"}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
